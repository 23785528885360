<template>
  <section>
    <v-row no-gutters>
      <v-col cols="12">
        <p class="text-h5 secondary--text mb-0">Configuración de proceso</p>
        <p class="text-subtitle-2 secondary--text">Compra de marcadores</p>
        <p class="text-h5 secondary--text mb-0">Forma de contratación</p>
        <p class="text-subtitle-2 secondary--text">Licitación competitiva</p>
        <p class="text-h5 secondary--text mb-0">Código</p>
        <p class="text-subtitle-2 secondary--text">LP351-3165-2022</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" v-if="!$vuetify.breakpoint.xs">
        <div>
          <v-row class="pt-5">
            <v-tabs vertical v-model="tab" background-color="bgMinsal">
              <v-tabs-slider color="secondary"></v-tabs-slider>
              <v-tab
                v-for="(item, index) in items"
                :key="index"
                class="secondary--text d-flex justify-start"
              >
                <v-icon>{{ item.icono }}</v-icon>
                <span class="pl-1">{{ item.text }}</span>
              </v-tab>
              <v-tabs-items v-model="tab" style="width: 100%">
                <v-tab-item v-for="(item, index) in contenido" :key="index">
                  <component class="mt-3 pr-3" :is="item.content" />
                </v-tab-item>
              </v-tabs-items>
            </v-tabs>
          </v-row>
        </div>
      </v-col>
      <v-col v-else cols="12">
        <app-tabs :contenido="contenido" :items="items" />
      </v-col>
    </v-row>
  </section>
</template>

<script>
import DescargaBases from "./components/Liquidacion/DescargaBasesComponent.vue";
import AdendasEnmiendas from "./components/Liquidacion/AdendasEnmiendasComponent.vue";
import RecepcionOfertas from "./components/Liquidacion/RecepcionOfertasComponent.vue";
import AperturaOfertas from "./components/Liquidacion/AperturaOfertaComponent.vue";
import EvaluacionOfertas from "./components/Liquidacion/EvaluacionOfertaComponent.vue";
import Resultados from "./components/Liquidacion/ResultadosComponent.vue";
import RecursoRevision from "./components/Liquidacion/RecursoRevisionComponent.vue";
import Consulta from "./components/Liquidacion/ConsultaViewComponent.vue";
import Contratacion from "./components/Liquidacion/ContratacionComponent.vue";
import Cierre from "./components/Liquidacion/CierreComponent.vue";
import Detalle from "./components/Liquidacion/DetalleGralContratoComponent.vue";
export default {
  name: "resumenLiquidacionView",
  components: {
    DescargaBases,
    Consulta,
    AdendasEnmiendas,
    RecepcionOfertas,
    AperturaOfertas,
    EvaluacionOfertas,
    Resultados,
    RecursoRevision,
    Contratacion,
    Cierre,
    Detalle,
  },
  data: () => ({
    tab: null,
    contenido: [
      {
        tab: "Descarga de bases",
        content: DescargaBases,
      },
      {
        tab: "Consultas",
        content: Consulta,
      },
      {
        tab: "Aclaraciones y enmiendas",
        content: AdendasEnmiendas,
      },
      {
        tab: "Recepción de ofertas",
        content: RecepcionOfertas,
      },
      {
        tab: "Apertura de ofertas",
        content: AperturaOfertas,
      },
      {
        tab: "Evaluación de ofertas",
        content: EvaluacionOfertas,
      },
      {
        tab: "Resultados",
        content: Resultados,
      },
      {
        tab: "Recursos de revisión/apelación",
        content: RecursoRevision,
      },
      {
        tab: "Contratación",
        content: Contratacion,
      },
      {
        tab: "Cierre",
        content: Cierre,
      },
      {
        tab: "Detalle general del contrato",
        content: Detalle,
      },
    ],
    items: [
      {
        text: "Descarga de bases",
        icono: "mdi-clipboard-check-outline",
      },
      {
        text: "Consultas",
        icono: "mdi-message-text",
      },
      {
        text: "Aclaraciones y enmiendas",
        icono: "mdi-file-document",
      },
      {
        text: "Recepción de ofertas",
        icono: "mdi-bookmark-check-outline",
      },
      {
        text: "Apertura de ofertas",
        icono: "mdi-format-list-numbered",
      },
      {
        text: "Evaluación de ofertas",
        icono: "mdi-cash-clock",
      },
      {
        text: "Resultados",
        icono: "mdi-playlist-check",
      },
      {
        text: "Recursos de revisión/apelación",
        icono: "mdi-file-outline",
      },
      {
        text: "Contratación",
        icono: "mdi-file-sign",
      },
      {
        text: "Cierre",
        icono: "mdi-close-box-outline",
      },
      {
        text: "Detalle general del contrato",
        icono: "mdi-file-outline",
      },
    ],
  }),
};
</script>

<style></style>
